<template>
  <td v-bind="$attrs" @click.prevent="() => $refs.tarea.focus()">
    <v-layout column align-center full-width>
      <v-textarea
        ref="tarea"
        class="cell-textarea"
        v-model="internalValue"
        :disabled="disabled"
        flat
        solo
        rows="1"
        auto-grow
        :placeholder="placeholder"
        hide-details
      ></v-textarea>
      <MaxlengthErrorMessage
        :value="value"
        :maxlength="maxlength"
      ></MaxlengthErrorMessage>
    </v-layout>
  </td>
</template>

<script>
export default {
  props: {
    value: {},
    placeholder: {
      type: String,
      default: "Wpisz..."
    },
    maxlength: {
      type: Number,
      default: 2000,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  components: {
    MaxlengthErrorMessage: () => import("@/components/controls/MaxlengthErrorMessage")
  }
};
</script>

<style scoped>
td {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.cell-textarea {
  width: 100%;
}
.cell-textarea ::v-deep textarea {
  margin-top: 4px !important;
  text-align: center;
}
.cell-textarea ::v-deep textarea::placeholder {
  text-align: center !important;
}
.cell-textarea ::v-deep .v-input__slot {
  min-height: 32px !important;
  margin-top: 7px !important;
}
</style>